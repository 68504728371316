<template>
	<div class="order" :class="bgClass">
		<tabs1 v-model="activeTab" :options="tabList" @change="tabChange"></tabs1>

		<div class="page-container"><router-view /></div>
	</div>
</template>

<script>
import workManagerMixin from '@views/shareAndDispatch/workManager/workManagerMixin'

export default {
	name: 'order',
	components: {},
  mixins:[workManagerMixin],
	data() {
		return {
			activeTab: '/workManager/order/shareSetting',
			tabList: [
				{
					title: '共享设置',
					path: '/workManager/order/shareSetting',
					bgClass: 'bg-style-1'
				},
				{
					title: '待审批',
					path: '/workManager/order/approveWait',
					bgClass: 'bg-style-2'
				},
				{
					title: '共享概览',
					path: '/workManager/order/shareOverview',
					bgClass: 'bg-style-3'
				},
				{
					title: '共享收益',
					path: '/workManager/order/shareEarnings',
					bgClass: 'bg-style-4'
				}
			]
		};
	},
	computed: {
		bgClass() {
			return this.tabList.filter(item => {
				return item.path == this.activeTab;
			})[0].bgClass;
		}
	},
	created() {
		this.activeTab = this.$route.path;
	},
	mounted() {},
	methods: {
    searchEvent:function() {
      this.$bus.emit('allDeviceUpdate', {})
    },
		tabChange(e) {
			this.activeTab = e;
			this.$router.replace(e).catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.order {
	height: 100%;
	background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
	background-size: 100% 197px;
	background-repeat: no-repeat;
	&.bg-style-3 {
		background-image: url('~@assets/images/workManager-order-bg-3.png');
	}
	&.bg-style-4 {
		background-image: url('~@assets/images/workManager-order-bg-4.png');
	}

	.page-container {
		height: calc(100% - 32px - 20px);
		margin-top: 10px;
		overflow-y: auto;
	}
}
</style>
