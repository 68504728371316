let workManagerMixin = {
  beforeDestroy() {
    this.removeLocalStorageEvent(this.searchEvent)
  },
  mounted() {
    this.addLocalStorageEventByKey('allDeviceUpdate', this.searchEvent)
  },
  methods:{

  }
}

export default workManagerMixin